<template>
  <div class="detailWrap">
    <div class="content">
      <h3 @click="goBack" class="drugDetail">药品信息</h3>
      <div class="bottom_line"></div>
      <div class="content_top">
        <p class="title">药品信息/药品详情</p>
        <div class="item">
          <div class="image">
            <img src="@/assets/img/drug_info.png" alt="" class="img1" />
          </div>
          <div class="info">
            <p class="info_title">合生元 益生菌冲剂 1.5g*26袋/盒</p>
            <p class="info_list">欧洲进口活性益生菌</p>
            <p class="info_list">净含量：39克</p>
            <p class="info_list">规格：1.5克/袋 x 26袋</p>
          </div>
        </div>
      </div>
      <div class="list_view">
        <p class="list_view_title">详细信息</p>
        <div class="list_view_image">
          <img src="@/assets/img/drugDetail.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {},
};
</script>

<style lang='scss' scoped>
.detailWrap {
  width: 100%;
  min-height: 800px;
//   background: #ffffff;
  overflow-x: auto;
  padding-bottom: 20px;
  p {
    margin: 0;
  }

  .content {
    width: 1200px;
    margin: auto;
    padding-top: 64px;
    font-size: 32px;
    .drugDetail {
        cursor: pointer;
    }
    .bottom_line {
      width: 117px;
      height: 5px;
      background: rgba(122, 80, 255, 0.53);
      opacity: 1;
      margin-top: 10px;
    }
    .content_top {
      .title {
        margin: 40px 0 40px 0;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.65);
      }
      .item {
        display: flex;
        .image {
          width: 400px;
          height: 400px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          flex: 1;
          //   border: 1px solid #000;
          box-sizing: border-box;
          padding-left: 100px;
          .info_title {
            // width: 478px;
            height: 45px;
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 33px;
            color: #3d3d3d;
            opacity: 1;
            margin-bottom: 24px;
          }
          .info_list {
            height: 33px;
            font-size: 24px;
            margin-bottom: 40px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 33px;
            color: rgba(61, 61, 61, 0.45);
            opacity: 1;
          }
        }
      }
    }
    .list_view {
      margin-top: 80px;
      width: 100%;
      // margin: auto;
      .list_view_title {
        width: 96px;
        height: 33px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        line-height: 33px;
        color: rgba(0, 0, 0, 0.65);
        opacity: 1;
      }
      .list_view_image {
        margin-left: 200px;
      }
    }
  }
}
</style>
